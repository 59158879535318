<template>
  <div class="container mt-5 px-2">
    <article class="message is-danger" v-if="!token">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>
    <div v-if="token">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><router-link :to="'/products-sales'">Products</router-link></li>
          <li>
            <a href="#" aria-current="page">
              <span>{{nsIdIndex}}</span> &nbsp;-&nbsp;
              <span>{{nsSku}}</span>
            </a>
          </li>
        </ul>
      </nav>

      <div class="tabs is-toggle">
        <ul>
          <li :class="{'is-active': routeName == 'ProductSales'}">
            <router-link :to="'/product-sales/' + nsIdIndex">
              <span>Sales</span>
            </router-link>
          </li>
          <li :class="{'is-active': routeName == 'ProductInventory'}">
            <router-link :to="'/product-inventory/' + nsIdIndex">
              <span>Inventory</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="mt-3">
        <div v-if="waiting">
          <span class="icon is-medium is-size-4">
            <i class="fas fa-spinner fa-pulse"></i>
          </span>
        </div>
        <div v-else>
          <div>
            <div>
              <h1 class="title is-5 mt-6">Options</h1>


              <div class="field">
                <label class="label">Products</label>
              </div>

              <div class="mb-3" v-for="(pg, i) in productGroups">
                <div class="field">
                  <label class="label">{{'Group ' + (i + 1)}}</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="Group Name" style="width:300px" v-model="pg.name">
                  </div>
                </div>
                <div class="field">
                  <div class="control" v-if="pg.products.length">
                    <div class="tags">
                      <span v-for="(p, j) in pg.products" :key="'products-' + i + '-' + j">
                        <span class="tag is-light is-medium">
                          {{p.name}}&nbsp;
                          <button class="delete is-small" @click="removeProductFromGroup(i, j)"></button>
                        </span>&nbsp;
                      </span>
                    </div>
                  </div>
                </div>
                <div class="field has-addons">
                  <p class="control">
                    <span class="select">
                      <select v-model="pg.selectedClass">
                        <option v-for="(c, j) in allClasses" :key="'classes-option-' + i + '-' + j">
                          {{c}}
                        </option>
                      </select>
                    </span>
                  </p>
                  <p class="control">
                    <span class="select">
                      <select v-model="pg.newProduct">
                        <option v-for="(p, j) in productsByClassName[pg.selectedClass]" :key="'products-option-' + i + '-' + j" :value="p">
                          {{p.name}}
                        </option>
                      </select>
                    </span>
                  </p>
                  <p class="control">
                    <a class="button" @click="$event => addProductIntoGroup(i)" :class="{'is-loading': loadingSales}">
                      Add
                    </a>
                  </p>
                </div>

                <div class="field is-grouped">
                  <div class="control">
                    <button class="button is-danger" @click="e => removeProductGroup(i)">Remove Group</button>
                  </div>
                </div>

              </div>

              <div class="field is-grouped mt-6">
                <div class="control">
                  <button class="button is-link" @click="addNewProductGroup">Add New Group</button>
                </div>
              </div>

              <div class="field mt-6">
                <label class="label">Subsidiary</label>
                <span class="select">
                  <select v-model="subsidiaryFilter">
                    <option>All</option>
                    <option v-for="(s, i) in subsidiaryOptions" :key="'subsidiary-option-' + i">
                      {{s}}
                    </option>
                  </select>
                </span>
              </div>

              <div class="field">
                <label class="label">Customer</label>
                <span class="select">
                  <select v-model="customerFilter">
                    <option>All</option>
                    <option v-for="(c, i) in customerOptions" :key="'customer-option-' + i">
                      {{c}}
                    </option>
                  </select>
                </span>
              </div>

              <div class="field">
                <label class="label">Date Range</label>
                <div class="control">
                  <label class="checkbox">
                    <input type="checkbox" v-model="dateRangeEnabled">
                    Enabled
                  </label>
                </div>
                <div class="control date-range-container" v-if="dateRangeEnabled">
                  <div class="columns">
                    <div class="column is-narrow from-date-picker">
                      <datepicker v-model="dateRangeFromDate" :inline="true" name="fromdate"></datepicker>
                    </div>
                    <div class="column to-date-picker">
                      <datepicker v-model="dateRangeToDate" :inline="true" name="todate"></datepicker>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field mt-6">
                <label class="label">Aggregation</label>
                <span class="select">
                  <select v-model="aggregation">
                    <option>daily</option>
                    <option>weekly</option>
                    <option>monthly</option>
                  </select>
                </span>
              </div>

              <div class="field">
                <label class="label">Sales Data</label>
                <span class="select">
                  <select v-model="saleData">
                    <option>quantity</option>
                    <option>amount</option>
                  </select>
                </span>
              </div>

            </div>
          </div>
          

          <div>
            <div>
              <h1 class="title is-5 mt-6">Charts</h1>
              <div class="field is-grouped is-grouped-multiline">
                <p class="control">
                  <span class="select">
                    <select v-model="otherOptions.chartTheme">
                      <option v-for="(ctm, i) in chartThemeOptions" :key="'chart-theme-option-' + i">
                        {{ctm}}
                      </option>
                    </select>
                  </span>
                </p>
                <p class="control">
                  <input class="input" type="number" placeholder="Chart Height" style="width:140px" v-model.number="otherOptions.chartHeight">
                </p>
              </div>
              
              <div class="chart-container" v-if="salesChartOption" :style="{'height': otherOptions.chartHeight + 'px'}">
                <v-chart autoresize :option="salesChartOption" :ref="'sales-chart'" :update-options="{notMerge: true}"  :theme="otherOptions.chartTheme"/>
              </div>
              <div class="mt-4">
                <div class="field is-grouped is-grouped-multiline">
                  <p class="control">
                    <input class="input" type="text" placeholder="Title" style="width:600px" v-model="otherOptions.title">
                  </p>
                  <p class="control">
                    <span class="select">
                      <select v-model="otherOptions.chartType">
                        <option>bar</option>
                        <option>line</option>
                        <option>smooth line</option>
                      </select>
                    </span>
                  </p>
                  <p class="control">
                    <input class="input" type="number" placeholder="Y Axis Max" style="width:120px" v-model.number="otherOptions.yAxisMax">
                  </p>
                </div>
              </div>
            </div>
            <div v-for="(pg, i) in productGroups" :key="'pg-charts-'+i">
              <h1 class="title is-6 mt-6">{{'Group ' + (i + 1) + ': ' + pg.name}}</h1>
              <div class="chart-container" :style="{'height': otherOptions.chartHeight + 'px'}">
                <v-chart autoresize :option="groupsChartOptions[i].itemSku" :update-options="{notMerge: true}" :theme="otherOptions.chartTheme"/>
              </div>
              <div class="chart-container" :style="{'height': otherOptions.chartHeight + 'px'}">
                <v-chart autoresize :option="groupsChartOptions[i].subsidiary" :update-options="{notMerge: true}" :theme="otherOptions.chartTheme"/>
              </div>
              <div class="chart-container" :style="{'height': otherOptions.chartHeight + 'px'}">
                <v-chart autoresize :option="groupsChartOptions[i].customer" :update-options="{notMerge: true}" :theme="otherOptions.chartTheme"/>
              </div>
            </div>
          </div>

          <div>
            <div class="table-section">
              <h1 class="title is-5 mt-6">Table</h1>
              <table class="table is-fullwidth is-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th v-for="(pg, i) in productGroups" :key="'col-header-' + i" class="has-text-right">
                      {{pg.name}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Total</th>
                    <td></td>
                    <th v-for="(pg, j) in productGroups" :key="'last-row-' + '-' + j" class="has-text-right">
                      {{formatData((pg.sales && pg.sales.totals) ? pg.sales.totals[saleData] : 0)}}
                    </th>
                  </tr>
                  <tr v-for="(d, i) in dates" :key="'row-date-' + i">
                    <td>{{i + 1}}</td>
                    <td>{{d}}</td>
                    <td v-for="(pg, j) in productGroups" :key="'row-' + i + '-' + j" class="has-text-right">
                      <div v-if="pg.sales" :class="{'is-clickable': pg.sales[aggregation][d]}" 
                        @click="$event => toggleDetails(j, d)">
                        {{formatData(pg.sales[aggregation][d] ? pg.sales[aggregation][d][saleData] : 0)}}
                      </div>
                      <div v-if="pg.sales && pg.sales[aggregation][d] && pg.sales[aggregation][d].showDetails" class="is-size-7">
                        <div v-for="(o, k) in pg.sales[aggregation][d].orders" :key="'order-' + i + '-' + j + '-' + k">
                          {{o.tranId + ', ' + o.itemSku + ',' + o.quantity + ', ' + o.amount + ', ' + o.customerName + ', ' + o.subsidiaryName}}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import dateFormat from 'dateformat'
import Vue from 'vue'


export default {
  name: 'ProductSales',
  components: {
    Datepicker
  },
  data () {
    return {
      error: '',
      waiting: false,
      loadingSales: false,
      sales: {},
      newProduct: {name: 'All'},
      allProducts: [],
      productGroups: [],
      saleData: 'quantity',
      dateRangeEnabled: false,
      dateRangeFromDate: new Date('2021-01-01T12:00:00Z'),
      dateRangeToDate: Date.now(),
      customerFilter: 'All',
      customerOptions: [],
      subsidiaryFilter: 'All',
      subsidiaryOptions: [],
      aggregation: 'daily',
      otherOptions: {
        title: '',
        chartType: 'bar',
        yAxisMax: null,
        chartHeight: 600,
        chartTheme: 'default',
      },
      chartThemeOptions: ['default', 'light', 'dark', 'vintage', 'westeros', 'essos', 'wonderland', 'walden', 'chalk', 'infographic', 'macarons', 'roma', 'shine', 'purple-passion', 'halloween', 'ovilia-green'],
      allClasses: [],
      selectedClass: null,
      productsByClassName: {},
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    routeName () {
      return this.$route.name
    },
    nsIdIndex () {
      return this.$route.params.nsIdIndex
    },
    nsSku () {
      if (!this.allProducts.length) {
        return ''
      }
      for (var p of this.allProducts) {
        if (p.itemId == this.nsIdIndex) {
          return p.name
        }
      }
      return ''
    },
    dates () {
      var dates = []
      for (var pg of this.productGroups) {
        if (pg.sales) {
          dates = dates.concat(Object.keys(pg.sales[this.aggregation]))
        }
      }
      dates = [...new Set(dates)]
      dates.sort().reverse()
      return dates
    },
    commonOption () {
      var yAxisMax = Number(this.otherOptions.yAxisMax)
      return {
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          feature: {
            restore: {},
            saveAsImage: {}
          }
        },
        yAxis: {
          type: 'value',
          max: (isNaN(yAxisMax) || !yAxisMax) ? null : yAxisMax
        },
      }
    },
    xAxis () {
      var option = {type: 'category'}
      if (!this.dates.length) {
        return option
      }
      var firstDate = new Date(this.dates[this.dates.length - 1] + 'T12:00:00Z')
      var lastDate = this.dates[0]
      var dates = []
      var date = firstDate
      while (date.toISOString().substring(0, 10) <= lastDate) {
        dates.push(date.toISOString().substring(0, 10))
        if (this.aggregation == 'daily') {
          date = new Date(date.getTime() + 24 * 3600 * 1000)
        } else if (this.aggregation == 'weekly') {
          date = new Date(date.getTime() + 7 * 24 * 3600 * 1000)
        } else if (this.aggregation == 'monthly') {
          if (date.getMonth() == 11) {
            date = new Date(date.getFullYear() + 1, 0, 1, 12)
          } else {
            date = new Date(date.getFullYear(), date.getMonth() + 1, 1, 12)
          }
        }
      }
      option.data = dates
      return option
    },
    salesSeries () {
      var series = []
      
      for (var pg of this.productGroups) {
        var chartType = this.otherOptions.chartType.includes('line') ? 'line' : 'bar'
        var smooth = this.otherOptions.chartType.includes('smooth')
        var s = {
          name: pg.name,
          type: chartType,
          smooth: smooth,
          data: [],
        }
        var ss = pg.sales && pg.sales[this.aggregation]
        if (ss) {
          for (var d of this.xAxis.data) {
            if (ss[d]) {
              s.data.push(ss[d][this.saleData])
            } else {
              s.data.push(0)
            }
          }
        }
        series.push(s)
      }
      return series
    },
    salesChartOption () {
      if (!this.productGroups.length) {
        return null
      }
      var option = {...this.commonOption}
      option.legend = {
        data: this.productGroups.map(p => p.name),
        top: 30
      }
      option.title = {
        text: (this.otherOptions.title || this.saleData.toUpperCase()),
        left: 'center',
      }
      option.xAxis = this.xAxis
      option.series = this.salesSeries
      option.dataZoom = [
        {start: 0, end: 100},
        {type: 'inside'}
      ]
      return option
    },
    productOptions () {
      var options = [{name: 'All'}]
      for (var p of this.allProducts) {
        if (p.className == this.selectedClass) {
          options.push(p)
        }
      }
      return options
    },
    groupsChartOptions () {
      var options = []
      for (var gp of this.productGroups) {
        if (gp.sales && gp.sales.totals) {
          options.push({
            itemSku: this.buildPieOption(gp.name, 'skus', gp.sales.itemSkuTotals),
            subsidiary: this.buildPieOption(gp.name, 'subsidiaries', gp.sales.subsidiaryTotals),
            customer: this.buildPieOption(gp.name, 'customers', gp.sales.customerTotals),
          })
        } else {
          options.push({})
        }
      }
      return options
    }
  },
  watch: {
    dateRangeEnabled: function (val) {
      this.buildSalesOnGroups()
    },
    dateRangeFromDate: function (val) {
      this.buildSalesOnGroups()
    },
    dateRangeToDate: function (val) {
      this.buildSalesOnGroups()
    },
    subsidiaryFilter: function (val) {
      this.buildSalesOnGroups()
    },
    customerFilter: function (val) {
      this.buildSalesOnGroups()
    },
  },
  methods: {
    getSalesForProducts (itemIds) {
      var promises = []
      for (let itemId of itemIds) {
        var promise = this.$http.get(this.server + '/myapp/get-sales-by-ns-id-index/' + itemId + '/').then(resp => {
          Vue.set(this.sales, itemId, resp.body)
        }, err => {
          this.error = err.body
        })
        promises.push(promise)
      }
      this.loadingSales = true
      var vm = this
      Promise.all(promises).then(data => {
        vm.buildCustomerOptions()
        vm.buildSubsidiaryOptions()
        vm.buildSalesOnGroups()
        vm.loadingSales = false
      }, err => {
        vm.loadingSales = false
      })
    },
    getProducts () {
      this.$http.get(this.server + '/myapp/get-sales-for-products/').then(resp => {
        this.allProducts = resp.body.map(p => {
          var ss = p.itemSku.split(':')
          return {...p, name: ss[ss.length-1].trim()}
        }).sort((a, b) => a.name.localeCompare(b.name))
        var allClasses = new Set()
        var productsByClassName = {}
        for(var p of this.allProducts) {
          allClasses.add(p.className)
          if (!productsByClassName[p.className]) {
            productsByClassName[p.className] = [{name: 'All'}]
          }
          productsByClassName[p.className].push(p)
          if (p.itemId == this.nsIdIndex) {
            this.selectedClass = p.className
            this.productGroups.push({
              name: p.name,
              products: [p],
              selectedClass: p.className,
              newProduct: {name: 'All'},
            })
          }
        }
        this.allClasses = [...allClasses].sort()
        this.productsByClassName = productsByClassName
        this.getSalesForProducts([this.nsIdIndex])
      }, err => {
        this.error = err.body
      })
    },
    addProductIntoGroup (i) {
      var pg = this.productGroups[i]
      var missingProducts = []
      if (pg.newProduct.name == 'All') {
        for (var p of this.productsByClassName[pg.selectedClass]) {
          if (!pg.products.includes(p) && p.name != 'All') {
            missingProducts.push(p)
          }
        }
      } else if (!pg.products.includes(pg.newProduct)) {
        missingProducts.push(pg.newProduct)
      }
      var itemIds = []
      for (var i = 0; i < missingProducts.length; i++) {
        let p = missingProducts[i]
        pg.products.push(p)
        if (!this.sales[p.itemId]) {
          itemIds.push(p.itemId)
        }
      }
      if (itemIds.length) {
        this.getSalesForProducts(itemIds)
      } else {
        this.buildSalesOnGroups()
      }
    },
    removeProductFromGroup (i, j) {
      var gp = this.productGroups[i]
      gp.products.splice(j, 1)
      this.productGroups.splice(i, 1, gp)
      this.buildSalesOnGroups()
    },
    addNewProductGroup () {
      this.productGroups.push({
        name: 'Group ' + (this.productGroups.length + 1),
        products: [],
        selectedClass: null,
        newProduct: {name: 'All'},
      })
    },
    removeProductGroup (i) {
      this.productGroups.splice(i, 1)
      this.buildSalesOnGroups()
    },
    buildCustomerOptions () {
      var customers = new Set()
      for (var orders of Object.values(this.sales)) {
        for (var order of orders) {
          customers.add(order.customerName)
        }
      }
      this.customerOptions = [...customers]
    },
    buildSubsidiaryOptions (orders) {
      var subsidiaries = new Set()
      for (var orders of Object.values(this.sales)) {
        for (var order of orders) {
          subsidiaries.add(order.subsidiaryName)
        }
      }
      this.subsidiaryOptions = [...subsidiaries]
    },
    buildSalesOnGroups () {
      var productGroups = []
      for (var pg of this.productGroups) {
        var orders = []
        var totals = {quantity: 0, amount: 0}
        for (var p of pg.products) {
          if (this.sales[p.itemId]) {
            for (var o of this.filterOrders(this.sales[p.itemId])) {
              orders.push(o)
              totals.quantity += o.quantity
              totals.amount += o.amount
            }
          }
        }
        pg.sales = {
          daily: this.buildDaily(orders),
          weekly: this.buildWeekly(orders),
          monthly: this.buildMonthly(orders),
          totals: totals,
          itemSkuTotals: this.buildTotals(orders, 'itemSku'),
          subsidiaryTotals: this.buildTotals(orders, 'subsidiaryName'),
          customerTotals: this.buildTotals(orders, 'customerName'),
        }
        productGroups.push(pg)
      }
      this.productGroups = productGroups
    },
    buildTotals(orders, name) {
      var valueMap = {}
      for (var o of orders) {
        if (!valueMap[o[name]]) {
          valueMap[o[name]] = {quantity: 0, amount: 0}
        }
        valueMap[o[name]].quantity += o.quantity
        valueMap[o[name]].amount += o.amount
      }
      return valueMap
    },
    filterOrders (orders) {
      var filtered = []
      for (var order of orders) {
        if (this.isDateRangeOk(order) && this.isSubsidiaryOk(order) && this.isCustomerOk(order)) {
          filtered.push(order)
        }
      }
      return filtered
    },
    isDateRangeOk (order) {
      if (!this.dateRangeEnabled) {
        return true
      }
      var tranDate = order.tranDate
      var fromDate = dateFormat(this.dateRangeFromDate, 'isoDate')
      var toDate = dateFormat(this.dateRangeToDate, 'isoDate')
      if (tranDate >= fromDate && tranDate <= toDate) {
        return true
      }
      return false
    },
    isSubsidiaryOk (order) {
      if (this.subsidiaryFilter == 'All') {
        return true
      }
      if (order.subsidiaryName == this.subsidiaryFilter) {
        return true
      }
      return false
    },
    isCustomerOk (order) {
      if (this.customerFilter == 'All') {
        return true
      }
      if (order.customerName == this.customerFilter) {
        return true
      }
      return false
    },
    buildDaily (orders) {
      var dailyMap = {}
      for (var order of orders) {
        if (!dailyMap[order.tranDate]) {
          dailyMap[order.tranDate] = {
            quantity: 0,
            amount: 0,
            orders: [],
            showDetails: false,
          }
        }
        dailyMap[order.tranDate].quantity += order.quantity
        var amount = dailyMap[order.tranDate].amount + order.amount
        dailyMap[order.tranDate].amount = Math.round(amount * 100) / 100
        dailyMap[order.tranDate].orders.push(order)
      }
      return dailyMap
    },
    buildWeekly (orders) {
      var weeklyMap = {}
      for (var order of orders) {
        var sunday = this.getSunday(order.tranDate)
        if (!weeklyMap[sunday]) {
          weeklyMap[sunday] = {
            quantity: 0,
            amount: 0,
            orders: [],
            showDetails: false,
          }
        }
        weeklyMap[sunday].quantity += order.quantity
        var amount = weeklyMap[sunday].amount + order.amount
        weeklyMap[sunday].amount = Math.round(amount * 100) / 100
        weeklyMap[sunday].orders.push(order)
      }
      return weeklyMap
    },
    getSunday (date) {
      var d = new Date(date + 'T12:00:00Z')
      var index = d.getDay()
      d.setDate(d.getDate() - index)
      return dateFormat(d, 'isoDate')
    },
    buildMonthly (orders) {
      var monthlyMap = {}
      for (var order of orders) {
        var firstDay = this.getFirstDay(order.tranDate)
        if (!monthlyMap[firstDay]) {
          monthlyMap[firstDay] = {
            quantity: 0,
            amount: 0,
            orders: [],
            showDetails: false,
          }
        }
        monthlyMap[firstDay].quantity += order.quantity
        var amount = monthlyMap[firstDay].amount + order.amount
        monthlyMap[firstDay].amount = Math.round(amount * 100) / 100
        monthlyMap[firstDay].orders.push(order)
      }
      return monthlyMap
    },
    getFirstDay (date) {
      var d = new Date(date + 'T12:00:00Z')
      var firstDay = new Date(d.getFullYear(), d.getMonth(), 1)
      return dateFormat(firstDay, 'isoDate')
    },
    formatData (d) {
      if (this.saleData == 'quantity') {
        return d
      }
      return d.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: false
      })
    },
    toggleDetails (groupIndex, date) {
      var gp = this.productGroups[groupIndex]
      gp.sales[this.aggregation][date].showDetails = !gp.sales[this.aggregation][date].showDetails
      this.productGroups.splice(groupIndex, 1, gp)
    },
    buildPieOption (groupName, keyName, totals) {
      var name = groupName + ' ' + this.saleData + ' by ' + keyName
      var data = []
      for (var k of Object.keys(totals)) {
        var value = this.formatData(totals[k][this.saleData])
        var ss = k.split(':')
        data.push({value: value, name: ss[ss.length-1].trim() + ': ' + value})
      }
      var option = {
        title: {
          text: name,
          left: 'center'
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: keyName,
            type: 'pie',
            radius: '70%',
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      return option
    },
  },
  mounted () {
    this.$nextTick(function(){
      this.getProducts()
    })
  },
}
</script>

<style scoped>
.chart-container {
  height: 600px;
  width: 100%;
}
.date-range-container {
  margin-top: 10px;
}
.from-date-picker {
  padding-right: 0px;
}
.to-date-picker {
  padding-left: 0px;
}
.table-section {
  overflow-x: auto;
}
</style>
